import { useMutation } from '@apollo/client'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { eventUserSelectedKitchenMutation } from './graphql'

import { Kitchen, Mutation } from '../../../api'
import { VERSION } from '../../../app'
import { useKitchen } from '../../../app/contexts/SelectedKitchen'
import { Loader } from '../../../components'
import { Icon, Typography } from '../../../components/newUi'
import { routes } from '../../../routes/Paths'
import { setInvoiceCode } from '../../../store/kitchen'
import { setSupplier } from '../../../store/supplier'
import { selectReturnPath, setReturnPath } from '../../../store/temp'
import { useTheme } from '../../../styles/newUi'
import { getInvoiceCode } from '../../Spending/Invoices/rest/getInvoiceCode'

type Props = {
  kitchen: Kitchen
  isNew?: boolean
}

export function LocationButton({ kitchen }: Props) {
  const { theme } = useTheme()
  const { selectedKitchen, selectKitchenById } = useKitchen()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const redirect = useSelector(selectReturnPath())
  const [loading, setLoading] = useState(false)

  const [eventUserSelectedKitchen] = useMutation<{
    eventUserSelectedKitchen: Mutation['eventUserSelectedKitchen']
  }>(eventUserSelectedKitchenMutation)

  async function onClick() {
    if (loading) {
      return
    }

    setLoading(true)

    try {
      dispatch(setSupplier(undefined))

      const kitchenNode = await selectKitchenById(kitchen.id)

      if (kitchenNode) {
        getInvoiceCode(kitchenNode)
          .then((code) => dispatch(setInvoiceCode(code)))
          .catch((e) => console.error('Unable to get invoice code.', e))
      }

      eventUserSelectedKitchen({
        variables: {
          appVersion: VERSION,
          kitchenId: kitchen.id,
        },
      }).catch((e) => console.error('Unable to log event.', e))

      if (redirect) {
        dispatch(setReturnPath(null))
        return navigate(`${redirect.pathname}${redirect.query}`)
      }

      return navigate(routes.Dashboard)
    } finally {
      setLoading(false)
    }
  }

  return (
    <button
      key={kitchen.id}
      onClick={onClick}
      className="flex justify-between items-center border-none rounded-lg shadow-sm mb-2 p-3 bg-white w-full"
    >
      <Typography
        variant="body1"
        className="space-x-2 !font-medium flex items-center"
      >
        {kitchen.isNew && (
          <span className="text-success-400 text-base">NEW</span>
        )}

        <span className="text-lg text-left">{kitchen.name}</span>
      </Typography>

      {loading ? <Loader size={20} /> : <></>}

      {!loading && selectedKitchen?.id === kitchen.id ? (
        <Icon
          iconName="tick"
          style={{
            color: theme.palette.primary[80].toHexString(),
            fontSize: '1.25rem',
          }}
        />
      ) : (
        <></>
      )}
    </button>
  )
}
