export const publicRoutes = {
  CompleteSignUpPhone: '/complete-sign-up-phone',
  CompleteSignUpPhone2: '/complete-sign-up-phone-2',
  CreateProfile: '/createprofile',
  ForgotPassword: '/forgotpassword',
  Join: '/join',
  NewPassword: '/new-password',
  ResetPassword: '/resetpassword',
  SignIn: '/signin',
  SignUp: '/signup',
  SignUpPhone: '/sign-up-phone',
  Verify: '/verify',
  VerifyEmail: '/verify-email',
  VerifyPhone: '/verify-phone',
  VerifySignUpPhone: '/verify-sign-up-phone',
  Welcome: '/welcome',
}

export const privateRoutes = {
  Add: '/add',
  Address: '/address',
  Addresses: '/addresses',
  All: '/all',
  Allergen: '/allergen',
  Approved: '/approved',
  Basket: '/basket',
  Book: '/book',
  Callback: '/callback',
  Changes: '/changes',
  Checkout: '/checkout',
  Confirm: '/confirm',
  Connect: '/connect',
  Costing: '/costing',
  Create: '/create',
  Dashboard: '/dashboard',
  Dish: '/dish',
  Edit: '/edit',
  EposNow: '/epos-now',
  Finance: '/finance',
  FoodFlash: '/flash',
  History: '/history',
  Home: '/',
  Ingredient: '/ingredient',
  Install: '/install',
  Installed: '/installed',
  Integrations: '/integrations',
  Intro: '/intro',
  Invoice: '/invoice',
  Kitchen: '/kitchen',
  List: '/list',
  Locations: '/locations',
  Me: '/me',
  Menu: '/menu',
  Onboard: '/onboard',
  Order: '/order',
  Pending: '/pending',
  Place: 'place',
  Plans: '/plans',
  Price: '/price',
  Product: '/product',
  Recipe: '/recipe',
  Refer: '/refer',
  Select: '/select',
  SetJobResponsibilities: '/set-job-responsibilities',
  SetJobRoles: '/set-job-roles',
  SetPassword: '/set-password',
  SetProfilePicture: '/set-profile-picture',
  Settings: '/settings',
  Spending: '/spending',
  Square: '/square',
  Stock: '/stock',
  Supplier: '/supplier',
  Take: '/take',
  Team: '/team',
  Todo: '/todo',
  Update: '/update',
  User: '/user',
  VerifyEmail: '/verify-email',
  View: '/view',
}

export const routes = {
  ...publicRoutes,
  ...privateRoutes,
}

export const ADD_INVOICE_PATH =
  `${routes.Spending}${routes.Invoice}${routes.Add}` as const

export const buildCreateRoute = (id: number | string, dish: boolean) =>
  `${routes.Create}/${String(id)}/${dish ? 'dish' : 'recipe'}`

export const buildViewRoute = (id: number | string, dish: boolean) =>
  `${routes.View}/${String(id)}/${dish ? 'dish' : 'recipe'}`
