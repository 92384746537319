import { Button, Typography } from '@getjelly/jelly-ui'
import { useNavigate } from 'react-router-dom'

import { Header } from './components/Header'

import { NewLayout } from '../../../../components/newUi'
import { routes } from '../../../../routes/Paths'

export function SquareIntro() {
  const navigate = useNavigate()

  return (
    <>
      <NewLayout
        onBack={() => navigate(routes.Settings + routes.Integrations)}
        subtitle="Integrations"
        title="Square"
        bottomContent={
          <div className="px-2 py-4 border-t border-primary-100">
            <Button
              onClick={() =>
                navigate(
                  routes.Settings +
                    routes.Integrations +
                    routes.Square +
                    routes.Install,
                )
              }
              label="Get Started"
              className="w-full"
            />
          </div>
        }
      />

      <div className="p-4 space-y-6 bg-primary-50">
        <Header />

        <Typography style="subtitle2" className="text-primary-900">
          On average it takes 5 minutes to connect with your Square account.
        </Typography>

        <Typography style="subtitle2" className="text-primary-900">
          Once connected, you can start cutting back on manual tasks as sales
          data automatically feed from your POS.
        </Typography>
      </div>
    </>
  )
}
