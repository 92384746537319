import { Anchor, Button, Typography } from '@getjelly/jelly-ui'
import { useNavigate } from 'react-router-dom'

import { Header } from './components/Header'

import { NewLayout } from '../../../../components/newUi'
import { routes } from '../../../../routes/Paths'

export function EposNowInstall() {
  const navigate = useNavigate()

  return (
    <>
      <NewLayout
        onBack={() =>
          navigate(routes.Settings + routes.Integrations + routes.EposNow)
        }
        subtitle="Integrations"
        title="Epos Now"
        bottomContent={
          <div className="px-2 py-4">
            <Button
              onClick={() =>
                navigate(
                  routes.Settings +
                    routes.Integrations +
                    routes.EposNow +
                    routes.Connect,
                )
              }
              label="I Have Installed Jelly On Epos Now"
              className="w-full"
            />
          </div>
        }
      />

      <div className="p-4 space-y-6 bg-primary-50">
        <Header />

        <div className="space-y-4">
          <Typography style="subtitle1" className="text-primary-900">
            Install Jelly App
          </Typography>

          <Typography style="subtitle2" className="text-primary-900">
            <span className="font-bold">Step 1:</span> click{' '}
            <Anchor
              onClick={() => {
                window.open(
                  'https://appstore.eposnow.com/s/app?appId=1686',
                  '_blank',
                )
              }}
              className="text-primary-900 !font-bold underline"
              style="subtitle2"
            >
              this link
            </Anchor>{' '}
            to be directed to the Jelly App page on your Epos Now account.
          </Typography>

          <Typography style="subtitle2" className="text-primary-900">
            <span className="font-bold">Step 2:</span> click Get Free App
          </Typography>

          <Typography style="subtitle2" className="text-primary-900">
            <span className="font-bold">Step 3:</span> make sure to tick all
            locations then click ‘Get Free App’ button to confirm.
          </Typography>

          <Typography style="subtitle2" className="text-primary-900">
            Once you see ‘Success’ screen. That’s it. The app is installed.
          </Typography>
        </div>
      </div>
    </>
  )
}
