import { gql } from '@apollo/client'

export const kitchenToPosAccountsQuery = gql`
  query kitchenToPosAccounts($kitchenId: Int!) {
    kitchenToPosAccounts(kitchenId: $kitchenId) {
      id
      posAccountId
      posAccount {
        posTypeId
      }
    }
  }
`

export const posTypeQuery = gql`
  query posType($name: String!) {
    posType(name: $name) {
      id
    }
  }
`

export const posAccountQuery = gql`
  query posAccount($externalId: String!, $posTypeId: Int!) {
    posAccount(externalId: $externalId, posTypeId: $posTypeId) {
      id
    }
  }
`

export const posLocationsQuery = gql`
  query posLocations($posAccountId: Int!) {
    posLocations(posAccountId: $posAccountId) {
      id
      name
      kitchenId
    }
  }
`

export const linkPosLocationMutation = gql`
  mutation linkPosLocation($data: LinkPosLocationInput!) {
    linkPosLocation(data: $data) {
      id
    }
  }
`

export const createKitchenToPosAccountMutation = gql`
  mutation createOneKitchenToPosAccount(
    $kitchenId: Int!
    $posTypeId: Int!
    $posAccountExternalId: String!
  ) {
    createOneKitchenToPosAccount(
      kitchenId: $kitchenId
      posTypeId: $posTypeId
      posAccountExternalId: $posAccountExternalId
    ) {
      id
    }
  }
`

export const deleteKitchenToPosAccountMutation = gql`
  mutation deleteOneKitchenToPosAccount($kitchenId: Int!, $posAccountId: Int!) {
    deleteOneKitchenToPosAccount(
      kitchenId: $kitchenId
      posAccountId: $posAccountId
    ) {
      id
    }
  }
`
