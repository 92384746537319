import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Button, Modal, Typography } from '@getjelly/jelly-ui'
import { IconCheck } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  deleteKitchenToPosAccountsMutation,
  kitchenToPosAccountsQuery,
  posLocationsQuery,
  posTypeQuery,
} from './graphql'

import {
  KitchenToPosAccount,
  Mutation,
  MutationDeleteOneKitchenToPosAccountArgs,
  PosLocation,
  PosType,
  Query,
  QueryKitchenToPosAccountsArgs,
  QueryPosLocationsArgs,
  QueryPosTypeArgs,
} from '../../../../api'
import { useKitchen } from '../../../../app/contexts/SelectedKitchen'
import { Loader } from '../../../../components'
import { NewLayout } from '../../../../components/newUi'
import { errorToast } from '../../../../components/toasts'
import { routes } from '../../../../routes/Paths'

export function SquareInstalled() {
  const navigate = useNavigate()
  const { selectedKitchen, refetchSelectedKitchen } = useKitchen()

  const [posLocations, setPosLocations] = useState<PosLocation[] | null>(null)
  const [showModal, setShowModal] = useState(false)
  const [disconnecting, setDisconnecting] = useState(false)

  const { data: { posType } = {} } = useQuery<
    { posType: Query['posType'] },
    QueryPosTypeArgs
  >(posTypeQuery, {
    variables: {
      name: 'Square',
    },
  })

  const [deleteKitchenToPosAccount] = useMutation<
    { deleteOneKitchenToPosAccount: Mutation['deleteOneKitchenToPosAccount'] },
    MutationDeleteOneKitchenToPosAccountArgs
  >(deleteKitchenToPosAccountsMutation)

  const { data: { kitchenToPosAccounts } = {} } = useQuery<
    { kitchenToPosAccounts: Query['kitchenToPosAccounts'] },
    QueryKitchenToPosAccountsArgs
  >(kitchenToPosAccountsQuery, {
    variables: {
      kitchenId: selectedKitchen?.id || 0,
    },
  })

  const [getPosLocations] = useLazyQuery<
    { posLocations: Query['posLocations'] },
    QueryPosLocationsArgs
  >(posLocationsQuery)

  useEffect(() => {
    if (!kitchenToPosAccounts || !posType) {
      return
    }

    async function getLocations(
      ktps: KitchenToPosAccount[],
      pt: PosType,
    ): Promise<PosLocation[]> {
      const result = []

      for (const ktp of ktps) {
        if (ktp.posAccount.posTypeId !== pt.id) {
          continue
        }

        const { data } = await getPosLocations({
          variables: {
            posAccountId: ktp.posAccountId,
          },
        })

        if (data?.posLocations) {
          result.push(...data.posLocations)
        }
      }

      return result
    }

    getLocations(kitchenToPosAccounts, posType)
      .then(setPosLocations)
      .catch((e) => {
        errorToast('Something went wrong fetching POS Locations')
        console.error(e)
      })
  }, [posType, kitchenToPosAccounts, getPosLocations])

  return (
    <>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <div className="space-y-8">
          <div className="space-y-2 text-center">
            <Typography style="h6" className="text-primary-900">
              Disconnect Square?
            </Typography>

            <Typography style="caption" className="text-primary-600">
              Are you sure you want to disconnect Square?
            </Typography>
          </div>

          <div className="space-y-2">
            <Button
              style="delete"
              loading={disconnecting}
              onClick={async () => {
                if (disconnecting) {
                  return
                }

                if (!posType) {
                  errorToast('Unable to disconnect integration.')
                  return
                }

                setDisconnecting(true)

                try {
                  for (const ktp of kitchenToPosAccounts || []) {
                    if (ktp.posAccount.posTypeId !== posType.id) {
                      continue
                    }

                    await deleteKitchenToPosAccount({
                      variables: {
                        kitchenId: selectedKitchen?.id || 0,
                        posAccountId: ktp.posAccountId,
                      },
                    })
                  }

                  await refetchSelectedKitchen()

                  setShowModal(false)
                  navigate(routes.Settings + routes.Integrations)
                } catch (error) {
                  console.error(error)
                  errorToast(
                    'Unable to disconnect Square. An unknown error has occurred.',
                  )
                } finally {
                  setDisconnecting(false)
                }
              }}
              className="w-full"
              label="Yes, Disconnect Square"
            />

            <Button
              style="secondary"
              onClick={() => setShowModal(false)}
              className="w-full"
              label="Cancel"
            />
          </div>
        </div>
      </Modal>

      <NewLayout
        onBack={() => navigate(routes.Settings + routes.Integrations)}
        subtitle="Integrations"
        title="Square"
        bottomContent={
          <div className="px-2 py-4 border-t border-primary-100">
            <Button
              style="delete"
              onClick={() => setShowModal(true)}
              label="Disconnect Integration"
              className="w-full"
            />
          </div>
        }
      />

      <div className="p-4 space-y-6 bg-primary-50">
        <div className="space-y-2">
          <Typography style="subtitle1" className="text-primary-900">
            Connected!
          </Typography>

          <Typography style="subtitle2" className="text-primary-900">
            Information is now feeding into Jelly
          </Typography>
        </div>

        <div className="bg-white rounded shadow">
          <div className="px-4 border-b border-primary-100 flex items-center justify-between">
            <div className="py-4">
              <Typography style="subtitle1" className="text-primary-900">
                Daily sales
              </Typography>
            </div>

            <IconCheck />
          </div>

          <div className="px-4 flex items-center justify-between">
            <div className="py-4">
              <Typography style="subtitle1" className="text-primary-900">
                Daily dishes sold
              </Typography>
            </div>

            <IconCheck />
          </div>
        </div>

        <div className="space-y-2">
          <Typography style="subtitle1" className="text-primary-900">
            Connected Square locations
          </Typography>

          <Typography style="subtitle2" className="text-primary-900">
            To add/remove locations, simply disconnect integration and restart.
          </Typography>
        </div>

        {posLocations === null && <Loader />}
        <div className="bg-white rounded shadow">
          {(posLocations ?? [])
            .filter((l) => l.kitchenId === selectedKitchen?.id)
            .map((location) => (
              <div
                key={location.id}
                className="px-4 border-b border-primary-100 last:border-none flex items-center justify-between"
              >
                <div className="py-4">
                  <Typography style="subtitle1" className="text-primary-900">
                    {location.name}
                  </Typography>
                </div>

                <IconCheck />
              </div>
            ))}
        </div>
      </div>
    </>
  )
}
