import { useLazyQuery, useMutation } from '@apollo/client'
import {
  Anchor,
  Button,
  Modal,
  TextInput,
  Typography,
} from '@getjelly/jelly-ui'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Header } from './components/Header'
import { createKitchenToPosAccountMutation, posTypeQuery } from './graphql'

import {
  Query,
  QueryPosTypeArgs,
  MutationCreateOneKitchenToPosAccountArgs,
  Mutation,
} from '../../../../api'
import { useKitchen } from '../../../../app/contexts/SelectedKitchen'
import { NewLayout } from '../../../../components/newUi'
import { routes } from '../../../../routes/Paths'

export function EposNowConnect() {
  const navigate = useNavigate()
  const { selectedKitchen } = useKitchen()

  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [guid, setGuid] = useState('')
  const [error, setError] = useState('')

  const [getPosType] = useLazyQuery<
    { posType: Query['posType'] },
    QueryPosTypeArgs
  >(posTypeQuery)

  const [createKitchenToPosAccount] = useMutation<
    { createOneKitchenToPosAccount: Mutation['createOneKitchenToPosAccount'] },
    MutationCreateOneKitchenToPosAccountArgs
  >(createKitchenToPosAccountMutation)

  return (
    <>
      <NewLayout
        onBack={() => navigate(routes.Settings + routes.Integrations)}
        subtitle="Integrations"
        title="Epos Now"
        bottomContent={
          <div className="px-2 py-4">
            <Button
              disabled={!guid.trim()}
              loading={loading}
              onClick={async () => {
                if (loading) return

                setLoading(true)
                setError('')

                try {
                  const uuidRegex =
                    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/

                  if (!uuidRegex.test(guid.trim())) {
                    return setShowModal(true)
                  }

                  const { data: { posType } = {} } = await getPosType({
                    variables: { name: 'Epos Now' },
                  })

                  if (!posType) {
                    return setShowModal(true)
                  }

                  const { data } = await createKitchenToPosAccount({
                    variables: {
                      kitchenId: selectedKitchen?.id || 0,
                      posAccountExternalId: guid,
                      posTypeId: posType.id,
                    },
                  })

                  if (!data?.createOneKitchenToPosAccount) {
                    return setShowModal(true)
                  }

                  navigate(
                    routes.Settings +
                      routes.Integrations +
                      routes.EposNow +
                      routes.Locations,
                  )
                } catch (e) {
                  console.error(e)
                  return setShowModal(true)
                } finally {
                  setLoading(false)
                }
              }}
              label="Connect"
              className="w-full"
            />
          </div>
        }
      />

      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <div className="space-y-8">
          <div className="space-y-2 text-center">
            <Typography style="h6" className="text-primary-900">
              Connection Issue
            </Typography>

            <div>
              <Typography style="caption" className="text-primary-600">
                We cannot establish connection. Please check the inputted GUID
                is correct.
              </Typography>

              <Typography style="caption" className="text-primary-600">
                If there’s still connection issue, make sure Jelly App is
                successfully installed in your Epos Now account
              </Typography>
            </div>
          </div>

          <Button
            onClick={() => setShowModal(false)}
            label="Ok"
            className="w-full"
          />
        </div>
      </Modal>

      <div className="p-4 space-y-6 bg-primary-50">
        <Header />

        <div className="space-y-4">
          <Typography style="subtitle1" className="text-primary-900">
            Input your Unique ID
          </Typography>

          <Typography style="subtitle2" className="text-primary-900">
            <span className="font-bold">Step 1:</span> click{' '}
            <Anchor
              onClick={() => {
                window.open(
                  'https://www.eposnowhq.com/Pages/BackOffice/CompanyInfoAdd.aspx',
                  '_blank',
                )
              }}
              className="text-primary-900 !font-bold underline"
              style="subtitle2"
            >
              this link
            </Anchor>{' '}
            to be directed to your Back office page in Epos Now account.
          </Typography>

          <div className="space-y-6">
            <Typography style="subtitle2" className="text-primary-900">
              <span className="font-bold">Step 2:</span> scroll down to the
              bottom of the page to find your GUID. It looks like:
            </Typography>

            <div className="flex items-center justify-center">
              <Typography style="caption" className="text-primary-900">
                xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx
              </Typography>
            </div>

            <Typography style="subtitle2" className="text-primary-900">
              <span className="font-bold">Step 3:</span> input the GUID below
            </Typography>

            <TextInput
              value={guid}
              onChange={setGuid}
              placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
              error={error}
            />
          </div>
        </div>
      </div>
    </>
  )
}
