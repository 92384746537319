import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Button, Pill, ToggleButton, Typography } from '@getjelly/jelly-ui'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Header } from './components/Header'
import {
  kitchenToPosAccountsQuery,
  linkPosLocationMutation,
  posLocationsQuery,
  posTypeQuery,
} from './graphql'

import {
  KitchenToPosAccount,
  Mutation,
  MutationLinkPosLocationArgs,
  PosLocation,
  PosType,
  Query,
  QueryKitchenToPosAccountsArgs,
  QueryPosLocationsArgs,
  QueryPosTypeArgs,
} from '../../../../api'
import { useKitchen } from '../../../../app/contexts/SelectedKitchen'
import { Loader } from '../../../../components'
import { NewLayout } from '../../../../components/newUi'
import { errorToast, successToast } from '../../../../components/toasts'
import { routes } from '../../../../routes/Paths'

export function SquareLocationSelect() {
  const navigate = useNavigate()
  const { selectedKitchen, refetchSelectedKitchen } = useKitchen()

  const [posLocations, setPosLocations] = useState<PosLocation[] | null>(null)
  const [changes, setChanges] = useState<Record<number, boolean>>({})
  const [linking, setLinking] = useState(false)

  const { data: { posType } = {}, loading: loadingPosType } = useQuery<
    { posType: Query['posType'] },
    QueryPosTypeArgs
  >(posTypeQuery, {
    variables: {
      name: 'Square',
    },
  })

  const { data: { kitchenToPosAccounts } = {}, loading: loadingPosAccount } =
    useQuery<
      { kitchenToPosAccounts: Query['kitchenToPosAccounts'] },
      QueryKitchenToPosAccountsArgs
    >(kitchenToPosAccountsQuery, {
      variables: {
        kitchenId: selectedKitchen?.id || 0,
      },
    })

  const [getPosLocations, { loading: loadingPosLocations }] = useLazyQuery<
    { posLocations: Query['posLocations'] },
    QueryPosLocationsArgs
  >(posLocationsQuery)

  useEffect(() => {
    if (!kitchenToPosAccounts || !posType) {
      return
    }

    async function getLocations(
      ktps: KitchenToPosAccount[],
      pt: PosType,
    ): Promise<PosLocation[]> {
      const result = []

      for (const ktp of ktps) {
        if (ktp.posAccount.posTypeId !== pt.id) {
          continue
        }

        const { data } = await getPosLocations({
          variables: {
            posAccountId: ktp.posAccountId,
          },
        })

        if (data?.posLocations) {
          result.push(...data.posLocations)
        }
      }

      return result
    }

    getLocations(kitchenToPosAccounts, posType)
      .then(setPosLocations)
      .catch((e) => {
        errorToast('Something went wrong fetching POS Locations')
        console.error(e)
      })
  }, [posType, kitchenToPosAccounts, getPosLocations])

  const [linkPosLocation] = useMutation<
    { posLocations: Mutation['linkPosLocation'] },
    MutationLinkPosLocationArgs
  >(linkPosLocationMutation, {
    awaitRefetchQueries: true,
    refetchQueries: ['posLocations'],
  })

  const loading = useMemo(
    () => loadingPosType || loadingPosAccount || loadingPosLocations,
    [loadingPosType, loadingPosAccount, loadingPosLocations],
  )

  useEffect(() => {
    const newChanges: Record<number, boolean> = {}
    for (const location of posLocations ?? []) {
      newChanges[location.id] = location.kitchenId === selectedKitchen?.id
    }

    setChanges(newChanges)
  }, [posLocations, selectedKitchen])

  return (
    <>
      <NewLayout
        onBack={() => navigate(routes.Settings + routes.Integrations)}
        subtitle="Integrations"
        title="Square"
        bottomContent={
          <div className="px-2 py-4">
            <Button
              loading={loading || linking}
              onClick={async () => {
                if (linking) return
                setLinking(true)

                try {
                  for (const [id, linked] of Object.entries(changes)) {
                    await linkPosLocation({
                      variables: {
                        data: {
                          kitchenId: selectedKitchen?.id || 0,
                          linked,
                          posLocationId: parseInt(id),
                        },
                      },
                    })
                  }

                  await refetchSelectedKitchen()

                  successToast('Successfully linked POS.')
                  navigate(
                    routes.Settings +
                      routes.Integrations +
                      routes.Square +
                      routes.Installed,
                  )
                } catch (e) {
                  errorToast('Unable to link POS location.')
                } finally {
                  setLinking(false)
                }
              }}
              label="Save"
              className="w-full"
            />
          </div>
        }
      />

      <div className="p-4 space-y-6">
        <Header />

        <div className="space-y-4">
          <Typography style="subtitle1" className="text-primary-900">
            Select locations
          </Typography>

          <Typography style="subtitle2" className="text-primary-900">
            Select only the relevant locations you want to connect to{' '}
            {selectedKitchen?.name}
          </Typography>
        </div>

        {posLocations === null ? (
          <Loader />
        ) : (
          <div>
            {posLocations.map((location) => (
              <div
                key={location.id}
                className="flex justify-between items-center w-full p-3 border-b border-primary-100"
              >
                <Typography
                  style="subtitle1"
                  className={`text-primary-900 line-clamp-1 break-words ${
                    location.kitchenId &&
                    selectedKitchen?.id !== location.kitchenId
                      ? 'line-through'
                      : ''
                  }`}
                >
                  {location.name}
                </Typography>

                {location.kitchenId === null ||
                location.kitchenId === selectedKitchen?.id ? (
                  <div className="shrink-0 flex items-center">
                    <ToggleButton
                      value={changes[location.id]}
                      size="small"
                      onChange={async () => {
                        const newChanges = { ...changes }
                        newChanges[location.id] = !newChanges[location.id]

                        setChanges(newChanges)
                      }}
                    />
                  </div>
                ) : (
                  <div className="shrink-0 flex items-center">
                    <Pill
                      variant="secondary"
                      label="Linked to another kitchen"
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  )
}
