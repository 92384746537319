import { Pill, Typography } from '@getjelly/jelly-ui'
import { IconCashRegister } from '@tabler/icons-react'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

import { useKitchen } from '../../../app/contexts/SelectedKitchen'
import { NewLayout, NavItem } from '../../../components/newUi'
import { routes } from '../../../routes/Paths'

export function Integrations() {
  const navigate = useNavigate()
  const { selectedKitchen } = useKitchen()

  const posStatus: Record<string, ReactNode> = {
    connected: <Pill variant="success" label="Connected" />,
    location: <Pill variant="secondary" label="Incomplete" />,
  }

  function getStatusPill(status?: string | null) {
    if (!status) return
    return posStatus[status]
  }

  return (
    <>
      <NewLayout
        onBack={() => navigate(routes.Settings)}
        subtitle="Settings"
        title="Integrations"
      />

      <div className="p-4 space-y-6">
        <div className="space-y-2">
          <Typography style="subtitle1" className="text-primary-900">
            Point of sales
          </Typography>

          <NavItem
            onClick={() => {
              if (selectedKitchen?.integrationState?.eposNow === 'connected') {
                return navigate(
                  routes.Settings +
                    routes.Integrations +
                    routes.EposNow +
                    routes.Installed,
                )
              }

              if (selectedKitchen?.integrationState?.eposNow === 'location') {
                return navigate(
                  routes.Settings +
                    routes.Integrations +
                    routes.EposNow +
                    routes.Locations,
                )
              }

              return navigate(
                routes.Settings +
                  routes.Integrations +
                  routes.EposNow +
                  routes.Intro,
              )
            }}
            subtext="Epos Now"
            endAdornment={getStatusPill(
              selectedKitchen?.integrationState?.eposNow,
            )}
            startAdornment={<IconCashRegister className="text-primary-900" />}
          />

          <NavItem
            onClick={() => {
              if (selectedKitchen?.integrationState?.square === 'connected') {
                return navigate(
                  routes.Settings +
                    routes.Integrations +
                    routes.Square +
                    routes.Installed,
                )
              }

              if (selectedKitchen?.integrationState?.square === 'location') {
                return navigate(
                  routes.Settings +
                    routes.Integrations +
                    routes.Square +
                    routes.Locations,
                )
              }

              return navigate(
                routes.Settings +
                  routes.Integrations +
                  routes.Square +
                  routes.Intro,
              )
            }}
            subtext="Square"
            endAdornment={getStatusPill(
              selectedKitchen?.integrationState?.square,
            )}
            startAdornment={<IconCashRegister className="text-primary-900" />}
          />
        </div>
      </div>
    </>
  )
}
